import React from 'react';
import myPic from '../../assets/Rahat-edited.png';
import reactjs from '../../assets/logos/react.svg';
import nodejs from '../../assets/logos/nodejs.svg';
import mongodb from '../../assets/logos/mongodb.svg';
import express from '../../assets/logos/expressjs.svg';
import js from '../../assets/logos/javascript.svg';
import tailwind from '../../assets/logos/tailwindcss.svg';
import { FaDownload } from 'react-icons/fa';
import './HeroSection.css';

const HeroSection = () => {
   return (
      <>
         <div className='max-w-[1350px] w-[95%] mx-auto flex flex-col lg:flex-row justify-between items-center relative'>
            <div className='w-[95%] lg:w-[50%] ml-0 mt-12 lg:mt-0 lg:ml-4 text-left md:text-center lg:text-left'>
               <div>
                  <h1 className='text-3xl text-white mb-2'>Hello, This is</h1>
                  <h1 className='text-6xl font-extrabold text-sky-400 mb-2'>Kazi Rahat Rezwan</h1>
                  <h3 className='text-4xl font-bold text-sky-400 mb-10'>
                     <span className='text-white'>I'm a</span> MERN Stack Developer
                  </h3>
                  <h5 className='text-2xl font-light text-white mb-3 lg:w-[90%]'>
                     Let's Empowering your web identity. I develop websites that ignite your
                     business
                  </h5>
               </div>
               <div>
                  <button className='btn bg-sky-500 hover:bg-base-200 border border-sky-600 text-white mr-5 mt-7'>
                     <a
                        target='_blank'
                        rel='noreferrer'
                        href='https://drive.google.com/file/d/1MS38i9SvuAQZth14LIMviuxYebgY47Jb/view?usp=share_link'
                        className='flex justify-center items-center gap-2'
                     >
                        <FaDownload /> Resume
                     </a>
                  </button>
                  <button className='btn bg-base-200 hover:bg-sky-500 border border-sky-600 shadow shadow-gray-600 text-white mb-3'>
                     <a href='#contact'>Hire Me</a>
                  </button>
               </div>
            </div>

            <div className='w-full lg:w-[50%] mt-[25%] md:mt-[18%] lg:mt-[10%] flex justify-center items-center relative'>
               <div className='w-[180px] h-[180px] md:w-[270px] md:h-[270px] lg:w-[350px] lg:h-[350px] bg-sky-200 rounded-full absolute top-2 md:top-3 lg:top-5 shadow-md shadow-white' />

               <div className='w-[280px] h-[280px] md:w-[395px] md:h-[395px] lg:w-[500px] lg:h-[500px]  rounded-full absolute top-[-10%] rotate-right'>
                  <div className='w-[280px] h-[280px] md:w-[395px] md:h-[395px] lg:w-[500px] lg:h-[500px] border-2 border-sky-200 rounded-full relative top-0'></div>

                  <div className='w-12 h-12 md:w-16 md:h-16 lg:w-[80px] lg:h-[80px] bg-white rounded-full flex justify-center items-center absolute top-[-8%] left-[42%] p-2 rotate-left'>
                     <img src={reactjs} alt='' />
                  </div>
                  <div className='w-12 h-12 md:w-16 md:h-16 lg:w-[80px] lg:h-[80px] bg-white rounded-full flex justify-center items-center absolute top-[20%] left-[-3%] p-2 rotate-left'>
                     <img src={nodejs} alt='' />
                  </div>
                  <div className='w-12 h-12 md:w-16 md:h-16 lg:w-[80px] lg:h-[80px] bg-white rounded-full flex justify-center items-center absolute top-[20%] right-[-3%] p-2 rotate-left'>
                     <img src={mongodb} alt='' />
                  </div>
                  <div className='w-12 h-12 md:w-16 md:h-16 lg:w-[80px] lg:h-[80px] bg-white rounded-full flex justify-center items-center absolute bottom-[20%] right-[-3%] p-2 rotate-left'>
                     <img src={express} alt='' />
                  </div>
                  <div className='w-12 h-12 md:w-16 md:h-16 lg:w-[80px] lg:h-[80px] bg-white rounded-full flex justify-center items-center absolute bottom-[20%] left-[-3%] p-2 rotate-left'>
                     <img src={js} alt='' className='rounded-full' />
                  </div>
                  <div className='w-12 h-12 md:w-16 md:h-16 lg:w-[80px] lg:h-[80px] bg-white rounded-full flex justify-center items-center absolute bottom-[-8%] left-[42%] p-2 rotate-left'>
                     <img src={tailwind} alt='' />
                  </div>
               </div>

               <div className='w-[250px] md:w-[300px] lg:w-[350px] flex justify-center items-center overflow-hidden z-10'>
                  <img src={myPic} alt='' className='w-full text-center' />
               </div>
            </div>
         </div>
      </>
   );
};

export default HeroSection;
