import React from "react";
import { FaDownload, FaGithub, FaTwitter, FaLinkedin } from "react-icons/fa";
import photo from "../../assets/Rahat-edited.png";
const About = () => {
   return (
      <section id="about" className="bg-base-200">
         <div className="max-w-[1200px] w-[95%] mx-auto py-[90px]">
            <div className="flex flex-col-reverse md:flex-row justify-start items-center gap-[70px]">
               <div className="border border-sky-500 rounded-md hidden md:block md:w-[40%] p-5">
                  <div className="bg-sky-300 rounded-md w-full h-[350px] lg:h-[450px] xl:h-[500px] overflow-hidden">
                     <img src={photo} alt="" />
                  </div>
               </div>

               <div className="w-full md:w-[60%]">
                  <h1 className="text-4xl font-extrabold mb-2">About Me</h1>
                  <hr className="w-[70px] border-t-[10px] border-t-sky-500 mb-5" />

                  <p>
                     Front End Developer, specializing in JavaScript, React JS, HTML5, CSS3,
                     JavaScript ES6, Tailwind CSS, Bootstrap 5, React Bootstrap, Daisy UI, Flowbite,
                     and Firebase Authentication. <br />I am comfortable using Nodejs, Express.JS,
                     creating REST API using MongoDB CRUD, and Secure API using JWT. I am also
                     familiar with TanStack or React Query and AXIOS. <br />I always love to learn
                     new things and implement those in my real work.
                  </p>

                  <button className="btn bg-sky-500 hover:bg-base-200 border border-sky-600 text-white mr-5 mt-7">
                     <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://drive.google.com/file/d/1MS38i9SvuAQZth14LIMviuxYebgY47Jb/view?usp=share_link"
                        className="flex justify-center items-center gap-2"
                     >
                        <FaDownload /> Resume
                     </a>
                  </button>

                  {/* Social Link */}
                  <div className="flex justify-start items-center gap-3 mt-5">
                     <h3 className="text-xl text-sky-400">Follow Me On: </h3>
                     <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://github.com/RahatRezwan"
                        className="text-lg text-sky-400 border-2 border-sky-400 rounded-full p-2 tooltip tooltip-bottom"
                        data-tip="GitHub"
                     >
                        <FaGithub />
                     </a>
                     <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://www.linkedin.com/in/kazi-rahat-rezwan/"
                        className="text-lg text-sky-400 border-2 border-sky-400 rounded-full p-2 tooltip tooltip-bottom"
                        data-tip="Linkedin"
                     >
                        <FaLinkedin />
                     </a>
                     <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://twitter.com/KaziRahatRezwan"
                        className="text-lg text-sky-400 border-2 border-sky-400 rounded-full p-2 tooltip tooltip-bottom"
                        data-tip="Twitter"
                     >
                        <FaTwitter />
                     </a>
                  </div>
               </div>
            </div>
         </div>
      </section>
   );
};

export default About;
